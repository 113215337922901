<template>
  <span class="count-down">{{surplusTime}}</span>
</template>

<script>
import { ref, onMounted, onUnmounted } from 'vue'
export default {
  name: 'count-down',
  props: {
    time: {
      type: Number,
      required: true
    },
    timeTotal: {
      type: Number,
      default: 60 * 60 * 2
    }
  },
  emits: ['finish'],
  setup(props, { emit }) {
    let timer = null
    const surplusTime = ref('')

    const getSurplusTime = () => {
      const surplusTimestamp = getSurplusTimestamp()
      const hours = surplusTimestamp / 60 / 60 % 24 | 0
      const minute = surplusTimestamp / 60 % 60 | 0
      const second = surplusTimestamp % 60 | 0
      return `${pullZero(hours)}小时${pullZero(minute)}分钟${pullZero(second)}秒`
    }

    const getSurplusTimestamp = () => {
      const nowDateTime = new Date().getTime().toString().slice(0, -3) - 0
      return props.timeTotal - (nowDateTime - props.time)
    }

    const pullZero = number => {
      if(number < 0) {
        clearInterval(timer)
        return '00'
      }

      return number <= 9 ? '0' + number : number
    }

    onMounted(() => {
      surplusTime.value = getSurplusTime()
      timer = setInterval(() => {
        if(getSurplusTimestamp() <= 0) {
          emit('finish')
        }
        surplusTime.value = getSurplusTime()
      }, 1000)
    })

    onUnmounted(() => {
      clearInterval(timer)
    })

    return {
      surplusTime
    }
  }
}
</script>

<style scoped>

</style>
