<template>
  <div class="payment" v-if="orderInfo">
    <div class="status-box">
      <div class="status-text">订单提交成功！去付款咯</div>
      <div class="status-time">
        剩余时间：<count-down :time="orderInfo.createtime" @finish="countDownFinish"></count-down>
      </div>
    </div>
    <div class="block">
      <div class="acea-row inline">
        <div class="label">订单编号：</div>
        <div class="value">{{orderInfo.order_sn}}</div>
      </div>
      <div class="acea-row inline">
        <div class="label">订单价格：</div>
        <div class="value">{{orderInfo.total_fee}}元</div>
      </div>
      <div class="acea-row inline">
        <div class="label">商品名称：</div>
        <div class="value">{{orderInfo.goods.goods_name}}</div>
      </div>
    </div>
    <div class="block">
      <div class="header">选择以下支付方式</div>
      <div class="acea-row pay-type">
        <a
          href="javascript:;"
          class="acea-row row-center-wrapper item"
          :class="{ active: currentPayType === item.value }"
          v-for="(item, index) in payTypes"
          :key="index"
          @click="changePayType(item.value)"
        >
          <img :src="item.icon" alt="">
          <span>{{item.text}}</span>
        </a>
      </div>
      <div class="acea-row row-right">
        <button
          type="button"
          class="btn-payment"
          @click="$router.push(`/payment/${orderInfo.order_sn}/detail/${currentPayType}/${orderInfo.total_fee}`)"
        >去支付</button>
      </div>
    </div>
  </div>
</template>

<script>
import CountDown from '@/components/count-down'
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getOrderInfo } from '@/api/order'

export default {
  name: 'paymentPage',
  setup() {
    const route = useRoute()
    const router = useRouter()

    // 支付方式
    const payTypes = ref([
      { icon: require('@/assets/images/wechat_pay.png'), text: '微信支付', value: 9 },
      { icon: require('@/assets/images/ali_pay.png'), text: '支付宝支付', value: 10 }
    ])
    const currentPayType = ref(9)
    const changePayType = value => {
      currentPayType.value = value
    }

    // 获取订单信息
    const orderInfo = ref(null)
    getOrderInfo({ order_sn: route.params.orderSn }).then(res => {
      orderInfo.value = res.data
    })

    // 支付剩余时间结束
    const countDownFinish = () => {
      router.back()
    }

    return {
      payTypes,
      currentPayType,
      changePayType,

      orderInfo,

      countDownFinish
    }
  },
  components: {
    [CountDown.name]: CountDown
  }
}
</script>

<style lang="less" scoped>
.status-box {
  width: 100%;
  height: 160px;
  background: url(~@/assets/images/orderBg.png) no-repeat;
  background-size: 100% 100%;
  color: white;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .status-text {
    font-size: 32px;
    margin-top: 20px;
    margin-bottom: 8px;
  }
  .status-time {
    font-size: 14px;
  }
}
.block {
  background-color: white;
  margin-bottom: 20px;
  padding: 20px;
  &:last-child {
    margin-bottom: 0;
  }
  .inline {
    font-size: 16px;
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
    .value {
      width: 800px;
    }
  }
  .header {
    font-size: 18px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #ddd;
  }
  .pay-type {
    padding: 30px 0;
    .item {
      position: relative;
      width: 210px;
      height: 85px;
      border: 1px solid #ddd;
      margin-right: 30px;
      overflow: hidden;
      &.active {
        border-color: #e93323;
        &::after {
          content: '\2714';
          box-sizing: border-box;
          position: absolute;
          bottom: 3px;
          right: 3px;
          font-size: 12px;
          color: white;
        }
        &::before {
          content: '';
          position: absolute;
          bottom: -20px;
          right: -20px;
          transform: rotate(45deg);
          width: 40px;
          height: 40px;
          background-color: #e93323;
        }
      }
      img {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }
      span {
        font-size: 16px;
      }
    }
  }
  .btn-payment {
    width: 180px;
    height: 45px;
    font-size: 16px;
    color: white;
    background-color: #e93323;
    border-radius: 4px;
    margin-top: 30px;
    border: 0;
    cursor: pointer;
  }
}
</style>
